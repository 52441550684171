import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/dla-mediow'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import Events from '../../components/Events'

const files = [
  {
    title: {
      pl: 'Omida Sea And Air S.A.',
      en: 'Omida Sea And Air S.A.',
    },
    desc: {
      pl: 'Logotyp | kolor + biały | SVG',
      en: 'Logo | color + white | SVG',
    },
    link: '/files/seaandair_logo_color_white.svg',
    targetBlank: true,
    // download: true,
  },
  {
    title: {
      pl: 'Omida Sea And Air S.A.',
      en: 'Omida Sea And Air S.A.',
    },
    desc: {
      pl: 'Logotyp | kolor + czarny | SVG',
      en: 'Logo | color + black | SVG',
    },
    link: '/files/seaandair_logo_color_black.svg',
    targetBlank: true,
    // download: true,
  },
  {
    title: {
      pl: 'Omida Sea And Air S.A.',
      en: 'Omida Sea And Air S.A.',
    },
    desc: {
      pl: 'Logotyp | monochrom biały | SVG',
      en: 'Logo | monochrome white | SVG',
    },
    link: '/files/seaandair_logo_mono_white.svg',
    targetBlank: true,
    // download: true,
  },
  {
    title: {
      pl: 'Omida Sea And Air S.A.',
      en: 'Omida Sea And Air S.A.',
    },
    desc: {
      pl: 'Logotyp | monochrom czarny | SVG',
      en: 'Logo | monochrome black | SVG',
    },
    link: '/files/seaandair_logo_mono_black.svg',
    targetBlank: true,
    // download: true,
  },
  {
    title: {
      pl: 'Omida Group',
      en: 'Omida Group',
    },
    desc: {
      pl: 'Logotyp | biały | SVG',
      en: 'Logo | white | SVG',
    },
    link: '/files/omidagroup_logo_white.svg',
    targetBlank: true,
    // download: true,
  },
  {
    title: {
      pl: 'Omida Group',
      en: 'Omida Group',
    },
    desc: {
      pl: 'Logotyp | czarny | SVG',
      en: 'Logo | black | SVG',
    },
    link: '/files/omidagroup_logo_black.svg',
    targetBlank: true,
    // download: true,
  },
  {
    title: {
      pl: 'Omida Sea And Air S.A.',
      en: 'Omida Sea And Air S.A.',
    },
    desc: {
      pl: 'Sygnet | kolor | SVG',
      en: 'Signature | color | SVG',
    },
    link: '/files/seaandair_signature.svg',
    targetBlank: true,
    // download: true,
  },
  {
    title: {
      pl: 'Omida Sea And Air S.A.',
      en: 'Omida Sea And Air S.A.',
    },
    desc: {
      pl: 'Paczka Logotypów',
      en: 'Logotypes Pack',
    },
    link: 'https://omida.pl/wp-content/uploads/2017/10/omida-sea-and-air-pack.zip',
    // targetBlank: true,
    // download: true,
  },
  {
    title: {
      pl: 'Omida Group',
      en: 'Omida Group',
    },
    desc: {
      pl: 'Paczka Logotypów',
      en: 'Logotypes Pack',
    },
    link: 'https://omida.pl/wp-content/uploads/2017/10/omida-group-pack.zip',
    // targetBlank: true,
    // download: true,
  },
  {
    title: {
      pl: 'Omida Logistics',
      en: 'Omida Logistics',
    },
    desc: {
      pl: 'Paczka Logotypów',
      en: 'Logotypes Pack',
    },
    link: 'https://omida.pl/wp-content/uploads/2017/10/omida-logistics-pack.zip',
    // targetBlank: true,
    // download: true,
  },
]

const DlaMediow = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/dla-mediow.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro data={{ ...intro, button }} image={image.childImageSharp} />
      <Main h={1} s={1} title={main.title[lang]} article={main.html}>
        <Events data={files} />
      </Main>
    </Layout>
  )
}

export default DlaMediow
